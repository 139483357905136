<template>
    <a href="https://ibb.co/vz2NYhN"><img src="https://i.ibb.co/Lp4T5rT/71704310-2556314437740019-5574854416198008832-n.jpg" alt="71704310-2556314437740019-5574854416198008832-n" border="0"></a>
</template>

<script>
    export default {
        name: "please"
    }
</script>

<style scoped>

</style>